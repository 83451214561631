<template>
  <div>
    <b-overlay :show="loading">
      <b-card>
        <div class="d-flex align-items-center">
          <b-button variant="primary" class="btn-icon" size="sm" :to="{ name: 'announce' }">
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
          <h3 class="mb-0 ml-50">{{ announceData.announce_title }}</h3>
        </div>
        <hr />
        <div v-html="announceData.announce_html_contents" />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      announceData: {
        id: '',
        announce_title: '',
        announce_sub_details: '',
        announce_html_contents: null,
      },
      loading: false,
    }
  },
  mounted() {
    const id = this.$route.params?.id
    if (id) {
      this.fetchAnnounceDetailsById(this.$route.params?.id)
    } else {
      this.$router.push({ name: 'announce', replace: true })
    }
  },
  methods: {
    async fetchAnnounceDetailsById(id) {
      this.loading = true
      const resp = await this.api.getV2(`api/announcement-data/${id}`).catch(() => null)
      this.loading = false
      if (resp && resp?.code === 200) {
        this.announceData = { ...resp.data }
      } else {
        this.gDisplayToast('ไม่พบประกาศ', 'ดูเหมือนว่าประกาศจะถูกลบไปแล้ว', 'danger')
        this.$router.push({ name: 'announce', replace: true })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
